import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useMutation } from "react-apollo-hooks"
import gql from "graphql-tag"
// import material ui
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"

// import app component
import Edges from "../components/edges"
import { useStore } from "../store"
// import Button from "components/button"

import * as theme from "../theme"

const Account = () => {
  const [
    {
      authState: { isAuthed, uniqueId },
      userState: { bookmarks, userInfo }
    },
    dispatch
  ] = useStore()

  const updatePassword = useMutation(UPDATE_PASSWORD)
  const updateEmail = useMutation(UPDATE_EMAIL)
  const updateName = useMutation(UPDATE_NAME)

  const [formError, updateFormErrors] = useState(false)
  const [firstName, setFirstName] = useState(userInfo ? userInfo.firstName : "")
  const [lastName, setLastName] = useState(userInfo ? userInfo.lastName : "")
  const [email, setEmail] = useState("")
  const [emailConfirmation, setEmailConfirmation] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirmation, setPasswordConfirmation] = useState("")

  useEffect(() => {
    dispatch({ type: "SET_SNACKBAR", payload: formError })
  }, [formError])

  useEffect(() => {
    if (userInfo) {
      setFirstName(userInfo.firstName)
      setLastName(userInfo.lastName)
    }
  }, [userInfo])

  const submitNameChange = async () => {
    updateFormErrors(false)

    if (uniqueId && userInfo && (firstName !== "" || lastName !== "")) {
      dispatch({ type: "SET_PROGRESS", payload: true })

      let response
      let message
      try {
        response = await updateName({
          variables: {
            id: uniqueId,
            firstName: firstName !== "" ? firstName : userInfo.firstName,
            lastName: lastName !== "" ? lastName : userInfo.lastName,
            mutationId: userInfo.ID
          }
        })
      } catch (e) {
        message = e.message
        message && updateFormErrors(message)
      }

      if (response?.data?.updateUser?.clientMutationId) {
        updateFormErrors("Your name has been updated.")
        dispatch({
          type: "SET_USER_DATA",
          payload: { user: { ...userInfo, firstName, lastName }, bookmarks }
        })
      } else {
        message
          ? updateFormErrors(message)
          : updateFormErrors("Something went wrong.")
      }
      dispatch({ type: "SET_PROGRESS", payload: false })
    }
  }

  const submitEmailChange = async () => {
    updateFormErrors(false)
    if (email && !email.includes("@")) {
      updateFormErrors("Please use a real email address.")
      return
    }
    if (email && email !== emailConfirmation) {
      updateFormErrors("Please double check your email address.")
      return
    }

    if (uniqueId && userInfo && email) {
      dispatch({ type: "SET_PROGRESS", payload: true })

      let response
      let message
      try {
        response = await updateEmail({
          variables: {
            id: uniqueId,
            email: email,
            mutationId: userInfo.ID
          }
        })
      } catch (e) {
        message = e.message
        message && updateFormErrors(message)
      }

      if (response?.data?.updateUser?.clientMutationId) {
        updateFormErrors("Your email has been updated to " + email + ".")
        dispatch({
          type: "SET_USER_DATA",
          payload: { user: { ...userInfo, email }, bookmarks }
        })
      } else {
        message
          ? updateFormErrors(message)
          : updateFormErrors("Something went wrong.")
      }
      dispatch({ type: "SET_PROGRESS", payload: false })
    }
  }

  const submitPasswordChange = async () => {
    updateFormErrors(false)

    if (password === "") {
      updateFormErrors("Please enter a new password.")
      return
    }
    if (password !== passwordConfirmation) {
      updateFormErrors("Please confirm your password.")
      return
    }

    if (uniqueId && userInfo && password) {
      dispatch({ type: "SET_PROGRESS", payload: true })

      let response
      let message

      try {
        response = await updatePassword({
          variables: {
            id: uniqueId,
            password: password,
            mutationId: userInfo.ID
          }
        })
      } catch (e) {
        message = e.message
        updateFormErrors(message)
      }

      if (response?.data?.updateUser?.clientMutationId) {
        updateFormErrors("Your password has been updated.")
      } else {
        message
          ? updateFormErrors(message)
          : updateFormErrors("Something went wrong.")
      }
      dispatch({ type: "SET_PROGRESS", payload: false })
    }
  }

  const catchReturn = ({ event, submit }) =>
    // enter key pressed
    event.charCode === 13 &&
    (submit === "name"
      ? submitNameChange()
      : submit === "email"
      ? submitEmailChange()
      : submit === "password"
      ? submitPasswordChange()
      : null)

  return (
    <Container>
      <StyledEdges size="lg">
        <Typography
          variant="h1"
          color="primary"
          gutterBottom
          style={{ marginBottom: "50px" }}
        >
          Account
        </Typography>
        {!!isAuthed ? (
          <>
            <>
              <Row>
                <Field>
                  <Label>First Name</Label>
                  <StyledInput
                    fullWidth
                    type="name"
                    value={firstName}
                    onChange={({ target }) => setFirstName(target.value)}
                    onKeyPress={e => catchReturn({ event: e, submit: "name" })}
                  />
                </Field>
                <Field>
                  <Label>Last Name</Label>
                  <StyledInput
                    fullWidth
                    type="name"
                    value={lastName}
                    onChange={({ target }) => setLastName(target.value)}
                    onKeyPress={e => catchReturn({ event: e, submit: "name" })}
                  />
                </Field>
              </Row>
              <StyledButton
                disabled={
                  !firstName ||
                  firstName === "" ||
                  (!lastName || lastName === "") ||
                  (firstName === userInfo.firstName &&
                    lastName === userInfo.lastName)
                }
                onClick={() => submitNameChange()}
              >
                Change Name
              </StyledButton>

              <YourEmail gutterBottom>
                Your email address is{" "}
                {!!userInfo ? <span>{userInfo.email}</span> : <span>...</span>}
              </YourEmail>
              <Row>
                <Field>
                  <Label>New Email</Label>
                  <StyledInput
                    fullWidth
                    type="email"
                    onChange={({ target }) => setEmail(target.value)}
                    onKeyPress={e => catchReturn({ event: e, submit: "email" })}
                  />
                </Field>
                <Field>
                  <Label>Confirm Email</Label>
                  <StyledInput
                    fullWidth
                    type="email"
                    onChange={({ target }) =>
                      setEmailConfirmation(target.value)
                    }
                    onKeyPress={e => catchReturn({ event: e, submit: "email" })}
                  />
                </Field>
              </Row>
              <StyledButton
                disabled={
                  !email ||
                  email === "" ||
                  email !== emailConfirmation ||
                  email === userInfo.email
                }
                onClick={() => submitEmailChange()}
              >
                Change Email
              </StyledButton>
              <Row>
                <Field>
                  <Label>New Password</Label>
                  <StyledInput
                    fullWidth
                    type="password"
                    onChange={({ target }) => setPassword(target.value)}
                    onKeyPress={e =>
                      catchReturn({ event: e, submit: "password" })
                    }
                  />
                </Field>

                <Field>
                  <Label>Confirm Password</Label>
                  <StyledInput
                    fullWidth
                    type="password"
                    onChange={({ target }) =>
                      setPasswordConfirmation(target.value)
                    }
                    onKeyPress={e =>
                      catchReturn({ event: e, submit: "password" })
                    }
                  />
                </Field>
              </Row>
              <Button
                disabled={password === "" || password !== passwordConfirmation}
                onClick={() => submitPasswordChange()}
              >
                Change Password
              </Button>
            </>
          </>
        ) : (
          <>
            <Typography variant="h3" gutterBottom>
              You are not logged in.
            </Typography>
            <Button
              onClick={() =>
                dispatch({ type: "SET_LOGIN_DIALOG", payload: true })
              }
            >
              Log in
            </Button>
          </>
        )}
      </StyledEdges>
    </Container>
  )
}

export default Account
const Container = styled.div`
  /* background: white; */
`
const StyledEdges = styled(Edges)`
  padding-top: 50px;
  padding-bottom: 50px;
`

const YourEmail = styled(Typography)`
  && {
    span {
      font-weight: bold;
      color: ${theme.colors.primary};
    }
  }
`
const StyledInput = styled(TextField)`
  && {
    background: white;
    padding: 0 10px;
    margin-bottom: 20px;
    .MuiInputBase-root {
      color: ${theme.colors.primary};
    }
  }
`
const Row = styled.div`
  @media (min-width: 650px) {
    flex-direction: row;
  }
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  &:first-child {
    margin-right: 5px;
  }
  &:last-child {
    margin-left: 5px;
  }
`
const StyledButton = styled(Button)`
  flex-shrink: 0;
  margin-bottom: 30px;
`

const Label = styled(Typography)`
  && {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 20px;
    color: #8e8a81;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
`
const Field = styled.div`
  width: 100%;
  &:first-of-type {
    margin-right: 15px;
  }
  &:last-of-type {
    margin-right: 0 !important;
  }
`

const UPDATE_PASSWORD = gql`
  mutation changePassword($id: ID!, $password: String!, $mutationId: String!) {
    updateUser(
      input: { id: $id, password: $password, clientMutationId: $mutationId }
    ) {
      clientMutationId
    }
  }
`
const UPDATE_EMAIL = gql`
  mutation changeEmail($id: ID!, $email: String!, $mutationId: String!) {
    updateUser(
      input: { id: $id, email: $email, clientMutationId: $mutationId }
    ) {
      clientMutationId
    }
  }
`
const UPDATE_NAME = gql`
  mutation changeName(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $mutationId: String!
  ) {
    updateUser(
      input: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        clientMutationId: $mutationId
      }
    ) {
      clientMutationId
    }
  }
`
